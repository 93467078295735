/* eslint-disable */
import React, { PureComponent } from 'react';

import Helmet from 'react-helmet';

import seo from 'tools/seo';

import Loading from 'components/Loading';

type Props = {};

class TermsAndConditions extends PureComponent<Props> {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    // https://magic.reactjs.net/htmltojsx.htm
    return (
      <>
        <Helmet>
          {seo.title('Terms and conditions - Flowfo')}
          {seo.description(
            "Etre ouvert d'esprit ne signifie pas que nous n'avons pas de règles. Tous les détails dans cette page.",
          )}
        </Helmet>
        {!loaded && <Loading />}
        <div className={`Page-Webflow ${loaded || 'd-none'}`}>
          {/* DEBUT HTML WEBFLOW termes et conditions fr */}

          {/* This site was created in Webflow. http://www.webflow.com */}
          {/* Last Published: Wed Apr 21 2021 01:41:26 GMT+0000 (Coordinated Universal Time) */}
          <meta charSet="utf-8" />
          <title>Terme et conditions - FRANCAIS</title>
          <meta content="Terme et conditions - FRANCAIS" property="og:title" />
          <meta content="Terme et conditions - FRANCAIS" property="twitter:title" />
          <meta content="Webflow" name="generator" />
          <link
            href="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/css/prices-flowfo.webflow.2ee13623e.css"
            onLoad={this.onLoad}
            rel="stylesheet"
            type="text/css"
          />
          {/*[if lt IE 9]><![endif]*/}
          <link href="https://uploads-ssl.webflow.com/img/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link href="https://uploads-ssl.webflow.com/img/webclip.png" rel="apple-touch-icon" />
          <div>
            <div className="divdeslangages">
              <a href="https://flowfo.me/terms-and-conditions" className="linklanguage w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5f95ec5bf00d8b10e6b4166c_THREE%20FLAGS.png"
                  alt=""
                  className="drapeauxronds"
                />
                <h2 className="changelangue">read in english</h2>
              </a>
            </div>
            <div className="divwithcontent">
              <h1 className="webflowheading1">Termes et conditions</h1>
              <h3 className="webflowheading3">Avant de commencer</h3>
              <p className="webflowparagraphe">
                Voici les conditions du contrat que vous passez avec Flowfo en vous inscrivant sur le site.
                <br />
                “Nous,” “nos” "notre" se réfère à Flowfo. “Flowfo” se réfère à la plateforme et aux services qui lui
                sont associés.
                <br />
                En utilisant Flowfo, vous déclarez être en accord avec ces conditions et les annexes{' '}
                <a href="https://flowfo.me/fr/prices" target="_blank" className="webflowlink">
                  Tarifs
                </a>{' '}
                et{' '}
                <a href="https://flowfo.me/fr/content-policy" target="_blank" className="webflowlink">
                  Politique de contenus
                </a>
                .<br />
                En cas d'achat sur le site, vous souscrivez aussi aux conditions de nos prestataires de paiement.{' '}
              </p>
              <h3 className="webflowheading3">Définitions</h3>
              <p className="webflowparagraphe">
                "Visiteur" : quelqu'un qui ne s'est pas inscrit sur le site ou qui ne s'est pas connecté à son compte.
                <br />
                "Utilisateur" : quelqu'un qui utilise le site en s'étant connecté à son compte.
                <br />
                "Créateur" : un utilisateur qui a créé une ou plusieurs pages d'Artwork.
                <br />
                "Artwork" : une page qui regroupe des news et des Flows.
                <br />
                "Articles numériques" : tout fichier (musique, image, texte…) qui peut être stocké sur un serveur web.
                <br />
                <strong>
                  "Flow" : le Flow ou "dossier de flux" est un dossier dans lequel le créateur stocke des fichiers.{' '}
                </strong>
                <br />
                "Flower" : un utilisateur qui suit et soutient un créateur.
                <br />
                "News" : article écrit et publié par le créateur.
              </p>
              <h3 className="webflowheading3 violet">Votre compte</h3>
              <p className="webflowparagraphe">
                <strong>Vous devez avoir au minimum 13 ans pour vous inscrire et 18 ans pour devenir créateur.</strong>
                <br />
                L'achat d'articles numériques par un mineur doit être fait avec l'accord des parents.
                <br />
                Vous êtes responsable des activités de votre compte et vous vous engagez à en préserver sa sécurité.
                <br />
                Contactez-nous immédiatement si vous pensez que votre compte est compromis (contact@flowfo.me)
                <br />
              </p>
              <p className="webflowparagraphe">
                Votre compte ne peut pas être transféré à une autre personne.
                <br />
                Vous pouvez supprimer votre compte à tout moment en vous rendant sur votre page des paramètres utilisateur https://flowfo.me/profile/settings.
                <br />
              </p>
              <h3 className="webflowheading3 violet">Conduite abusive</h3>
              <p className="webflowparagraphe">
                Si vous violez les termes de ce contrat, nous pouvons supprimer votre compte.
                <br />
                Ne faites rien d'illégal, ne tentez pas de saturer le site ou de corrompre son code.
                <br />
                Si vous êtes un créateur qui collecte des fonds sur Flowfo, nous pouvons être tenus responsables de ce
                que vous faites avec ces fonds. En conséquence, nous pouvons examiner ce que vous faites avec votre
                adhésion en dehors de notre plateforme.
              </p>
              <h3 className="webflowheading3">Modèle de ventes</h3>
              <p className="webflowparagraphe">
                Flowfo vend les articles numériques stockés dans les Flows par les créateurs.
                <br />
                Le type de Flow et son prix sont définis par le créateur propriétaire du Flow.
              </p>
              <p className="webflowparagraphe">
                Un <strong>Flow gratuit (FREE FLOW)</strong> est un dossier dont le contenu est accessible à tous
                (visiteurs inclus).
                <br />
                Un Flow gratuit peut devenir payant (et inversement).
                <br />
                <br />
                Un <strong>Flow payant (PAYING FLOW)</strong> est un dossier dont le contenu est accessible uniquement
                aux utilisateurs ayant acheté un droit d'accès. <br />
                Cet accès reste valable durant toute la vie du Flow.
                <br />
                <br />
                Un <strong>Flow par abonnement (SUBSCRIPTION FLOW)</strong> est un dossier dont le contenu est
                accessible uniquement aux abonnés du Flow (renouvellement mensuel). <br />
                <br />
                <strong>Les prix des Flows ont un minimum de 2€ et un maximum de 50€ (hors TVA).</strong>
                <br />
              </p>
              <h3 className="webflowheading3 red">Flow par abonnement</h3>
              <p className="webflowparagraphe">
                Le Flow par abonnement (SUBSCRIPTION FLOW) se fait sur une base mensuelle renouvelable tacitement.
                <br />
                <strong>
                  Un abonné peut arrêter son abonnement n'importe quand. <br />‍
                </strong>
                L'accès au Flow lui reste acquis jusqu'à la fin du mois en cours. Aucune remise ne peut être consentie.
                <br />‍<br />
                <strong>
                  Le renouvellement de l'abonnement se fait toujours le 1er du mois. Il n'y a pas de période glissante.
                </strong>
                <br />
                L'accès au Flow étant instantané, la 1ère échéance est toujours payée immédiatement et ne fait l'objet
                d'aucune remise (même si la fin du mois est proche).
                <br />
                <br />
                Les abonnements aux Flows se cumulent : si un utilisateur s'abonne à plusieurs Flows, il paie Flow A +
                Flow B + Flow C...<strong>‍</strong>
                <strong>
                  <br />
                </strong>
              </p>
              <p className="webflowparagraphe">
                Le prix de l'abonnement peut être changé n'importe quand par le créateur. <br />
                Ce changement est pris en compte immédiatement pour les nouveaux abonnés et démarre le 1er du mois
                suivant pour les autres. Nous recommandons vivement aux créateurs de communiquer par avance sur leur
                changement de prix (en passant par les news).
                <br />
                Nous sommes susceptibles de bloquer tout changement qui nous paraîtrait suspicieux.
                <br />‍<br />
                <strong>
                  Le contenu du Flow peut être changé à tout moment par le créateur.
                  <br />
                </strong>
                Cela signifie que les fichiers peuvent être renommés, modifiés ou effacés.
                <br />
                <strong>
                  En tant qu'abonné au Flow, vous comprenez que son contenu est fluctuant. <br />
                  Le prix de l'abonnement ne dépend pas du contenu du Flow.
                  <br />
                </strong>
                Afin d'éviter une perte de contenu, nous rappelons aux abonnés qu'ils peuvent télécharger le Flow quand
                bon leur semble.
                <strong>
                  <br />
                </strong>
                <br />
                <strong>Le Flow lui même peut être effacé à tout moment. </strong>Le renouvellement de l'abonnement est
                alors stoppé.
                <br />
                En cas de suppression du Flow, tous les abonnés sont perdus pour le créateur. Il n'y a pas d'annulation
                possible.
                <strong />
              </p>
              <h3 className="webflowheading3 red">Le Flow payant</h3>
              <p className="webflowparagraphe">
                L'accès au contenu du Flow payant (PAYING FLOW) se fait via une vente unique.
                <br />
                Si des nouveaux fichiers sont placés dans le Flow après la vente, ils sont automatiquement lisibles par
                l'acheteur (sans avoir à payer de nouveau).
                <strong>
                  <br />
                  <br />
                  Le prix peut être changé à tout moment par le créateur. Il peut même devenir gratuit.
                </strong>
                <br />
                Le changement de prix n'affecte pas l'accès des anciens acheteurs.
                <br />
                En tant que créateur, vous comprenez que le Flow est vendu une seule et unique fois.
                <strong>
                  {' '}
                  <br />
                </strong>
                En tant qu'acheteur, vous comprenez que le Flow puisse avoir un prix différent (voir gratuit) après
                votre achat. <br />
                Aucune remise ne peut être effectuée.
                <br />
                <br />
                <strong>
                  Le contenu du Flow peut être changé à tout moment par le créateur.
                  <br />
                </strong>
                Cela signifie que les fichiers peuvent être renommés, modifiés ou effacés.
                <br />
                <strong>
                  Le Flow lui même peut être effacé par le créateur.
                  <br />
                </strong>
                Afin d'éviter une perte de contenu, nous rappelons aux acheteurs qu'ils peuvent télécharger le Flow
                quand bon leur semble.<strong>‍</strong>
                <strong>‍</strong>
              </p>
              <h3 className="webflowheading3 violet">Rémunération des créateurs</h3>
              <p className="webflowparagraphe">
                Les paiements aux créateurs (PAYOUT) sont déclenchés{' '}
                <strong>le 10 de chaque mois (heure de Paris).</strong> <br />
                <strong>
                  La devise source est l'Euro €. <br />‍
                </strong>
                Aucune avance ne peut être consentie.
                <strong>
                  <br />‍
                </strong>
                <br />
                La méthode du paiement est choisie par le créateur.
                <br />
                <strong>SEPA TRANSFER</strong> - pour les créateurs européens ayant un compte bancaire compatible.
                <br />
                <strong>PAYPAL</strong> - nous devons payer les créateurs via <em>des transactions commerciales </em>qui
                peuvent générer des frais. L'option <em>Friends and family </em>n'est malheureusement pas possible.{' '}
                <br />
                Si la devise souhaitée est différente de l'Euro, Paypal peut aussi vous facturer des frais de change.
                <br />
                Pour plus de détails, visitez{' '}
                <a href="http://www.paypal.com" target="_blank" className="webflowlink">
                  www.paypal.com
                </a>
                ‍
              </p>
              <p className="webflowparagraphe">
                Nous pouvons bloquer ou suspendre les paiements en cas de violation de nos politiques ou pour des
                raisons de conformité, y compris la collecte d'informations fiscales. <br />
                Lorsque les paiements sont retardés ou bloqués, nous essayons de vous en communiquer la raison
                rapidement. <br />
              </p>
              <h3 className="webflowheading3 violet">Commission</h3>
              <p className="webflowparagraphe">
                Flowfo facture une commission sur chaque transaction réalisée. <br />
                <strong>
                  La commission est de : 10,9% du prix hors taxes + 0,30€
                  <br />
                </strong>
                Toute vente, tout abonnement ou renouvellement d'abonnement est considéré comme une transaction. <br />
                Si un utilisateur achète 3 Flows lors d'une même commande, il y a 3 transactions. <br />
              </p>
              <h3 className="webflowheading3 violet">Attestation de paiement</h3>
              <p className="webflowparagraphe">
                Toute rémunération fait l'objet d'une attestation de paiement envoyée au créateur par email.
                <br />
                Cette attestation détaille le nombre de ventes, les prix et les commissions. <br />
                Elle est envoyée le 10 de chaque mois si des transactions ont été réalisées.
                <br />
                En tant que créateur, vous autorisez Flowfo à éditer ce document en votre nom. <br />
                Contactez-nous immédiatement à{' '}
                <a href="mailto:payment@flowfo.me?subject=A%20propos%20des%20paiements" className="webflowlink">
                  payment@flowfo.me
                </a>{' '}
                si vous ne recevez pas ce document (vérifiez vos spams).
                <br />
              </p>
              <h3 className="webflowheading3 violet">Obligations fiscales</h3>
              <p className="webflowparagraphe">
                Notre modèle est celui du e-commerce. Nous ne sommes pas une marketplace.
                <br />
                En tant que créateur, vous vendez à Flowfo des articles numériques que nous revendons au public.
                <br />‍<br />
                <strong>Vous êtes un créateur particulier :</strong> vos rémunérations doivent être déclarées auprès des
                services fiscaux. Les attestations de paiements font office de justificatif. <br />
                Si vous réalisez des ventes régulières et importantes, vous devrez certainement changer de statut et
                payer de la TVA. Consultez un fiscaliste ou votre administration fiscale pour connaître les seuils
                limites.
                <br />
                <strong>
                  <br />
                  Vous êtes une créateur avec une société{' '}
                </strong>
                : vous acceptez que les rémunérations versées par Flowfo incluent toutes les taxes que vous pourriez
                avoir à payer dans votre état ou pays.
                <br />
                Si vous êtes dans l'obligation de générer votre propre facture, merci de nous l'envoyer par email à
                payment@flowfo.me en vous basant sur les détails de notre attestation de paiement.
                <br />
              </p>
              <h3 className="webflowheading3">TVA</h3>
              <p className="webflowparagraphe">
                Flowfo vend les articles digitaux en son nom propre.
                <br />
                Le calcul et la collecte de la TVA sont de notre responsabilité.
                <strong>
                  <br />
                  La TVA n'affecte en rien la rémunération due au créateur (basée sur le prix hors taxes).
                </strong>
                <br />
                <strong>
                  ‍<br />
                  Vous êtes un créateur particulier :{' '}
                </strong>
                aucune démarche relative à la TVA n'est à effectuer.
                <strong>
                  <br />
                  Vous êtes créateur avec une société :
                </strong>{' '}
                vous vendez à Flowfo des articles numériques que nous revendons ensuite. Anticipez dans le prix de vos
                Flows toutes taxes que vous pourriez avoir à payer dans votre état ou pays.
                <br />
                La TVA facturée par Flowfo aux clients finaux ne peut en aucun cas vous être reversée.
                <br />
              </p>
              <h3 className="webflowheading3">Droit de rétractation</h3>
              <p className="webflowparagraphe">
                Le contenu d'un Flow étant disponible instantanément après une transaction,{' '}
                <strong>vous renoncez expressément à votre droit de rétractation.</strong>
                <br />
              </p>
              <h3 className="webflowheading3">Litige lors d'une transaction</h3>
              <p className="webflowparagraphe">
                Nous rappelons aux créateurs que tout utilisateur peut contester un paiement internet plusieurs jours
                après la transaction. <strong>Si cette contestation est légitime (preuves à l'appui)</strong>, nous
                devons rembourser l'utilisateur. <br />
                Le prix hors taxe est alors déduit de votre rémunération. Si votre solde devient négatif, nous pouvons
                récupérer ces fonds sur les paiements futurs.
                <br />
                En cas de contestation légitime, nous verrouillons tous les fichiers au plus vite mais vous acceptez que
                l'acheteur ait eu accès à vos fichiers durant une période donnée, sans contrepartie.
                <br />
              </p>
              <h3 className="webflowheading3">Contenus et fonctionnalités</h3>
              <p className="webflowparagraphe">
                Notre{' '}
                <a href="https://flowfo.me/fr/content-policy" target="_blank" className="webflowlink">
                  politique de contenus
                </a>{' '}
                est disponible en annexe et fait partie intégrante du contrat que vous passez en vous inscrivant à
                Flowfo.
                <br />
                Nous examinons de manière proactive certaines pages et publications. <br />
                Nous examinons les pages signalées pour identifier les violations potentielles de nos directives. <br />
                Nous pouvons résilier ou suspendre votre compte à tout moment à notre discrétion. <br />
                Nous pouvons également annuler tout abonnement et supprimer toute description, fichier, news,
                commentaire à notre discrétion.
                <br />
                Dans la plupart des situations, nous travaillerons avec le créateur pour résoudre le problème.
                <br />
                <br />
                <strong>
                  Flowfo n'est pas une solution de stockage ou sauvegarde de fichiers.
                  <br />
                  Gardez toujours des copies sur d'autres supports.
                </strong>
                <br />
                <br />
                Nous testons constamment des nouvelles fonctionnalités dans le but d'améliorer Flowfo.
                <br />
                Vous acceptez d'utiliser la plateforme en l'état et renoncez à toute compensation en cas de régressions
                ou suppressions d'une fonctionnalité.
              </p>
              <h3 className="webflowheading3 red">Droits des créations</h3>
              <p className="webflowparagraphe">
                <strong>
                  En tant que créateur, vous gardez l'entière propriété des fichiers que vous postez sur Flowfo.
                </strong>
                <br />
                Vous nous autorisez à les afficher, classer, trier, vendre, stocker sur la plateforme.
                <br />
                Cette autorisation est valable pour le monde entier, tant que vous êtes inscrit sur le site et que vos
                travaux y sont disponibles. Vous ne pouvez pas nous interdire de présenter vos travaux dans une zone
                géographique particulière ou durant certaines périodes.
                <br />
                <br />
                Vous autorisez Flowfo à communiquer en utilisant votre matériel de création (couvertures, bannières,
                pages, extraits d'images...) Vous acceptez de voir votre travail édité et recadré pour correspondre aux
                contraintes de formats de certains médias (réseaux sociaux notamment).
                <br />
                Le but de cette licence est strictement limité à promouvoir votre art et la plateforme. <br />
                Nous n'essaierons jamais de voler vos créations ou de les utiliser de manière abusive. <br />
                <br />
                <strong>
                  En tant que créateur, vous autorisez les utilisateurs à télécharger vos créations pour une utilisation
                  hors ligne. <br />
                  En tant qu'utilisateur, vous acceptez que le contenu disponible sur Flowfo soit à des fins privées
                  uniquement.{' '}
                </strong>
                <br />
                Toute utilisation commerciale est strictement interdite (que les fichiers soient disponibles
                gratuitement ou non).
              </p>
              <h3 className="webflowheading3 red">Droits de Flowfo</h3>
              <p className="webflowparagraphe">
                Vous pouvez utiliser notre nom et notre logo pour promouvoir votre page Flowfo, mais vous ne pouvez pas
                les utiliser pour autre chose sans notre autorisation. <br />
                Nos créations sont protégées par les lois sur les droits d'auteur, les marques commerciales et les
                secrets commerciaux. Ceci inclue le texte sur le site, le logo, ses variantes, le nom du site et son
                code.
                <br />
              </p>
              <h3 className="webflowheading3">Poursuites judiciaires</h3>
              <p className="webflowparagraphe">
                Vous êtes solidairement responsable si nous sommes poursuivis en justice en raison de votre utilisation
                ou de votre conduite sur Flowfo. Vous nous indemniserez de toutes les pertes et responsabilités, y
                compris les frais juridiques, qui pourraient découler d'une telle poursuite et/ou d'une condamnation.
                <br />
                Si vous perdez de l'argent à la suite de l'utilisation de Flowfo, tout paiement qui vous est versé est
                limité au montant d'argent que nous avons reçu lors de vos transactions.
                <br />
                En cas de problème, la juridiction compétente est un tribunal français basé à Paris.
                <br />
              </p>
              <h3 className="webflowheading3">Données personnelles</h3>
              <p className="webflowparagraphe">
                Nous partageons vos données personnelles avec notre prestataire de paiement.
                <br />
                Afin de rendre les abonnements possibles, notre prestataire de paiement (au standard PCI) garde les
                coordonnées bancaires de chaque acheteur.
                <br />
                Flowfo ne stocke pas ces informations sur ses propres serveurs et n'a accès qu'à une version tronquée.
                <br />
                Tout utilisateur peut retirer ses infos via l'API disponible sur la page
                https://flowfo.me/profile/settings
                <br />
                Ses éventuels abonnements seront alors stoppés en fin de mois.
                <br />
              </p>
              <h3 className="webflowheading3">A propos de Flowfo</h3>
              <p className="webflowparagraphe">
                Flowfo SAS est situé en France – 6, rue des Abbesses – 75018 PARIS. RCS 847 854 676 - Paris (30/01/2019)
                avec un capital de 15 000€. Numéro fiscal : FR67847854676. <br />
                Vous pouvez nous contacter par email à{' '}
                <a href="mailto:contact@flowfo.me?subject=A%20propos%20de%20vos%20conditions" className="webflowlink">
                  contact@flowfo.me
                </a>
                <br />
                Le site est hébergé par AWS – Europe.{' '}
              </p>
              <div className="divendblank" />
            </div>
          </div>
          {/*[if lte IE 9]><![endif]*/}

          {/* FIN HTML WEBFLOW termes et conditions francais*/}
        </div>
      </>
    );
  }
}

export default TermsAndConditions;
